import { json, LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useMatches,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { useEffect } from "react";

import { ErrorComponent } from "~/components/error";
import { Navigation } from "~/components/navigation";
import { Notifications } from "~/components/notifications";
import { TooltipProvider } from "~/components/ui/tooltip";
import { Sentry } from "~/lib/sentry";
import { SessionService } from "~/lib/session.server";
import { getToast } from "~/lib/toast.server";
import globalStyles from "~/tailwind.css?url";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: globalStyles, as: "style" }];

export async function loader({ request }: LoaderFunctionArgs) {
  const { toast, headers } = await getToast(request);
  return json(
    {
      user: await SessionService.getUser(request),
      toast,
    },
    { headers },
  );
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="commitHash" content={import.meta.env.VITE_COMMIT_HASH} />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <Meta />
        <Links />
      </head>
      <body className="sm:bg-secondary">
        {/* <GlobalLoader /> */}
        <Notifications />
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  const { user } = useLoaderData<typeof loader>();
  const matches = useMatches();

  const excludeNavPaths = ["auth", "external"];
  const shouldExcludeNav = matches.some((m) => excludeNavPaths.includes(m.pathname.split("/")[1]));

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
      });
      return;
    }

    Sentry.setUser(null);
  }, [user]);

  if (shouldExcludeNav) {
    return (
      <TooltipProvider>
        <main>
          <Outlet />
        </main>
      </TooltipProvider>
    );
  }

  return (
    <TooltipProvider>
      <Navigation />
      <main className="pb-2 md:pl-72 lg:pr-2 lg:pt-2">
        <div className="min-h-[calc(100dvh-1rem)] bg-background p-5 md:px-10 md:pb-24 md:pt-8 lg:rounded-lg lg:border lg:shadow-sm">
          <Outlet />
        </div>
      </main>
    </TooltipProvider>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <ErrorComponent />;
}
