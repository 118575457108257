import { Link, NavLink, NavLinkProps } from "@remix-run/react";
import {
  IconArchiveFilled,
  IconMapPinFilled,
  IconSettingsFilled,
  IconStarFilled,
  IconUserFilled,
} from "@tabler/icons-react";
import { useState } from "react";

import { Logo } from "~/components/logo";
import { Avatar, AvatarFallback, AvatarImage } from "~/components/ui/avatar";
import { Separator } from "~/components/ui/separator";
import { Sheet, SheetContent, SheetDescription, SheetTitle, SheetTrigger } from "~/components/ui/sheet";
import { useUser } from "~/hooks/useUser";
import { APP_ROLES } from "~/lib/constants";
import { cn, userHasRoles } from "~/lib/utils";

const navLinks = [
  { label: "Tickets", to: "/tickets", icon: IconStarFilled },
  { label: "Assets", to: "/assets", icon: IconArchiveFilled },
  { label: "Locations", to: "/locations", icon: IconMapPinFilled },
];

const adminNavLinks = [
  { label: "Users", to: "/users", icon: IconUserFilled },
  { label: "Settings", to: "/settings", icon: IconSettingsFilled },
];

export function Navigation() {
  const user = useUser();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const isAdmin = userHasRoles(user.roles, [APP_ROLES.ADMINISTRATOR]);

  return (
    <>
      {/* Mobile Nav */}
      <nav className="flex h-16 justify-between border-b bg-white p-5 shadow-sm md:hidden">
        <Link to="/" className="flex items-center gap-2 text-muted-foreground">
          <Logo />
        </Link>
        <Sheet open={mobileNavOpen} onOpenChange={setMobileNavOpen}>
          <SheetTrigger className="md:hidden">
            <span className="text-sm font-medium text-muted-foreground">Menu</span>
          </SheetTrigger>
          <SheetTitle className="sr-only">Navigation</SheetTitle>
          <SheetDescription className="sr-only">Navigation links</SheetDescription>
          <SheetContent side="top" className="rounded-b-lg">
            <ul className="mt-6 flex flex-col gap-0.5">
              {navLinks.map((props) => {
                return (
                  <li key={props.to}>
                    <MobileNavLink setNavOpen={setMobileNavOpen} {...props} />
                  </li>
                );
              })}
              {isAdmin ? (
                <>
                  <Separator className="my-4" />
                  <p className="mb-4 text-xs font-medium uppercase tracking-wider text-muted-foreground">ADMIN</p>
                  {adminNavLinks.map((props) => {
                    return (
                      <li key={props.to}>
                        <MobileNavLink setNavOpen={setMobileNavOpen} {...props} />
                      </li>
                    );
                  })}
                </>
              ) : null}
            </ul>
            <div className="mt-auto"></div>
            <Separator className="my-4" />
            <LoggedInUser />
          </SheetContent>
        </Sheet>
      </nav>
      {/* Desktop Nav */}
      <nav className="fixed hidden min-h-dvh w-72 flex-col bg-secondary p-5 md:flex">
        <div className="flex items-center gap-2 text-muted-foreground">
          <Logo />
        </div>
        <Separator className="my-4" />
        <ul className="flex flex-col gap-0.5">
          {navLinks.map((props) => {
            return (
              <li key={props.to}>
                <DesktopNavLink {...props} />
              </li>
            );
          })}
          {isAdmin ? (
            <>
              <Separator className="my-4" />
              <p className="mb-4 text-xs font-medium uppercase tracking-wider text-muted-foreground">ADMIN</p>
              {adminNavLinks.map((props) => {
                return (
                  <li key={props.to}>
                    <DesktopNavLink {...props} />
                  </li>
                );
              })}
            </>
          ) : null}
        </ul>
        <div className="mt-auto"></div>
        <Separator className="my-4" />
        <LoggedInUser />
      </nav>
    </>
  );
}

type Props = {
  label: string;
  to: NavLinkProps["to"];
  icon: typeof IconArchiveFilled;
  setNavOpen?: (open: boolean) => void;
};

export function MobileNavLink({ label, to, icon: Icon, setNavOpen }: Props) {
  return (
    <NavLink
      to={to}
      onClick={setNavOpen ? () => setNavOpen(false) : undefined}
      className={({ isActive }) =>
        cn(isActive && "bg-secondary", "relative flex items-center gap-2 rounded p-2 font-medium hover:bg-primary/5")
      }
    >
      {({ isActive }) => {
        return (
          <>
            <Icon className={cn(isActive ? "text-foreground" : "text-muted-foreground", "size-5")} />
            <span>{label}</span>
          </>
        );
      }}
    </NavLink>
  );
}

export function DesktopNavLink({ label, to, icon: Icon }: Props) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        cn(
          isActive ? "text-foreground" : "text-muted-foreground",
          "relative flex items-center gap-2 rounded p-2 text-sm font-medium transition hover:bg-primary/5",
        )
      }
      unstable_viewTransition
    >
      {({ isActive }) => {
        return (
          <>
            <div
              className={cn(
                isActive ? "opacity-100" : "opacity-0",
                "absolute -left-5 h-[50%] w-0.5 rounded-lg bg-primary transition-opacity",
              )}
            ></div>
            <Icon className={cn("size-[18px]")} />
            <span>{label}</span>
          </>
        );
      }}
    </NavLink>
  );
}

function LoggedInUser() {
  const user = useUser();
  return (
    <>
      <div className="flex gap-2">
        <Avatar>
          <AvatarImage src={user.avatar || undefined} alt={user.name} />
          <AvatarFallback>
            {user.name.split(" ")[0][0]}
            {user.name.split(" ")[1] ? user.name.split(" ")[1][0] : null}
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-col gap-0.5">
          <span className="truncate text-sm font-medium text-foreground">{user.name}</span>
          <span className="truncate text-xs text-muted-foreground">{user.email}</span>
        </div>
      </div>
      <form action="/auth/logout" method="post" className="mt-4">
        <button type="submit" className="flex items-center gap-2 rounded text-xs font-medium text-muted-foreground">
          <span>Logout</span>
        </button>
      </form>
    </>
  );
}
